import React from 'react';
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
import { connect } from 'react-redux';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
  Modal,
  FormGroup,
  Input
} from 'reactstrap';
// core components
import { withCookies, useCookies } from 'react-cookie';
import Parse from "widget/parse";

// import { logout } from 'store/actions/auth';
// import { setCompany } from 'store/actions/comp';
import Link from 'components/Link/Link';

function WhiteNavbar(props = {}) {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [studentId, setStudentId] = React.useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['studentObj']);

  const {
    history,
    // cookies,
    companyObj = {},
    user = {},
    webnavArr = [],
    webpageArr = [],
    companies = [],
    changeCompany,
  } = props;
  // const dbLang = 'name';
  // const dbLang1 = (cookies && cookies.get('dbLang')) || 'name';

  React.useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();

    // console.log('studentObj', cookies.get('studentObj'));
    // console.log('cookies.studentObj', cookies.studentObj);
    // const sObj = cookies.get('studentObj');
    // cookies.set('studentObj', sObj, { path: '/' });

  });


  const setLogout = () => {
    const { cookies, companyObj } = props;
    // cookies.set('studentObj', {}, { path: '/' });
    setCookie('studentObj', {}, { path: '/' });
    // this.setState({ studentObj: {}, studentId: '' });
  }

  const getStudentObj = async () => {
    const { cookies, companyObj } = props;
    // const { studentId } = this.state;
    const studentObj = await Parse.findDoc('LineUsers', { studentId });

    const { profile } = studentObj;
    const sObj = { ...studentObj, ...profile };
    // this.setState({ studentObj: sObj });
    // console.log(studentObj)

    if (studentObj && studentObj.objectId) {
      // cookies.set('studentObj', sObj, { path: '/' });
      setCookie('studentObj', sObj, { path: '/' });
      setLogin(false)
    } else {
      alert('登入失敗');
    }
  }

  const { themeConf = {} } = companyObj
  const { img1File = {}, } = themeConf
  return (<>
    {bodyClick ? (
      <div
        id="bodyClick"
        onClick={() => {
          document.documentElement.classList.toggle('nav-open');
          setBodyClick(false);
          setCollapseOpen(false);
        }}
      />
    ) : null}
    <Navbar className="fixed-top" expand="lg" id="navbar-main">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand id="navbar-brand" to="/" tag={Link}>
            {/* Paper Kit PRO React */}
            {img1File.url ? <Image size='large' src={img1File.url} /> : companyObj.name}
            {/* | 線上點燈 */}
          </NavbarBrand>
          <UncontrolledTooltip placement="bottom" target="navbar-brand">
            {/* Paper Kit PRO React */}
            {img1File.url ? <Image size='large' src={img1File.url} /> : companyObj.name}
          </UncontrolledTooltip>
          <button
            className="navbar-toggler"
            id="navigation"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle('nav-open');
              setBodyClick(true);
              setCollapseOpen(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse navbar isOpen={collapseOpen}>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                課程介紹
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/lesson/thinking" tag={Link}>
                  運算思維與程式設計
                </DropdownItem>
                <DropdownItem to="/lesson/ai" tag={Link}>
                  自然科學與人工智慧
                </DropdownItem>
                <DropdownItem to="/lesson/programming" tag={Link}>
                  程式語言導論
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                互動遊戲
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/game/basket" tag={Link}>
                  物品交換
                </DropdownItem>
                <DropdownItem to="/game/doors" tag={Link}>
                  三門問題
                </DropdownItem>
                <DropdownItem to="/game/betting" tag={Link}>
                  下注策略
                </DropdownItem>
                <DropdownItem to="/game/puzzle" tag={Link}>
                  數字推盤
                </DropdownItem>
                {/*
                  <DropdownItem to="/game/stack" tag={Link}>
                    電梯問題
                  </DropdownItem>
                  <DropdownItem to="/game/hanoi" tag={Link}>
                    河內塔
                  </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                線上編程
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/programming/coding" tag={Link}>
                  程式設計
                </DropdownItem>
                <DropdownItem to="/programming/fractal" tag={Link}>
                  碎形繪圖
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*   <NavItem>
              <Link
                className="nav-link"
                to={`/coding`}
                tag={Link}
              >
                線上編程
              </Link>
            </NavItem> */}
            {/* <NavItem>
                <Link
                  className="nav-link"
                  to={`/sharing`}
                  tag={Link}
                >
                  上課心得
                </Link>
              </NavItem> */}
            <NavItem>
              <Link
                className="nav-link"
                to={`/resources`}
                tag={Link}
              >
                網路資源
              </Link>
            </NavItem>
            {/* <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Link
                </NavLink>
              </NavItem> */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="mr-2" color="default" caret nav>
                關於講師
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
                <DropdownItem to="/lecturer/schedule" tag={Link}>
                  開課資訊
                </DropdownItem>
                <DropdownItem to="/lecturer/intro" tag={Link}>
                  講師介紹
                </DropdownItem>
                {/* <DropdownItem to="/lecturer/interest" tag={Link}>
                    興趣領域
                  </DropdownItem> */}
                {/* <DropdownItem to="/lecturer/business" tag={Link}>
                    事業經營
                  </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            {cookies.studentObj && cookies.studentObj.studentId ?
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  data-toggle="dropdown"
                  // height="30"
                  // width="30"
                  tag={Link}
                  color="default"
                  nav
                  style={{ cursor: 'pointer' }}
                >
                  <div className="profile-photo-small">
                    <img
                      alt="..."
                      className="img-circle img-responsive img-no-padding"
                      src={cookies.studentObj.pictureUrl}
                      style={{ width: '40px', height: '40px' }}
                    />
                  </div>
                </DropdownToggle>
                {/* <DropdownToggle className="mr-2" color="default" caret nav>
                  個人
                </DropdownToggle> */}
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem header>{cookies.studentObj.studentId} {cookies.studentObj.studentName}</DropdownItem>
                  <DropdownItem to="/profile" tag={Link}>
                    個人資訊
                  </DropdownItem>
                  <DropdownItem to="/setting" tag={Link}>
                    設定
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem to="#" tag={Link}
                    onClick={() => setLogout()}
                  >
                    登出
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> :
              <NavItem>
                <Link
                  className="nav-link"
                  // to={`/coding`}
                  // tag={Link}
                  onClick={() => setLogin(true)}
                  style={{ cursor: 'pointer' }}
                >
                  登入
                </Link>
              </NavItem>}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
    <Modal
      isOpen={login}
      toggle={() => setLogin(false)}
      className="modal-register"
    >
      <div className="modal-header no-border-header text-center">
        <button
          className="close"
          type="button"
          onClick={() => setLogin(false)}
        >
          <span>×</span>
        </button>
        <h6 className="text-muted">歡迎來到</h6>
        <h3 className="modal-title text-center">中原商設 陳胤辰講師</h3>
        <p>請先登入</p>
      </div>
      <div className="modal-body">
        <FormGroup>
          <label>學號</label>
          {/* <Input defaultValue="" placeholder="Email" type="text" /> */}
          <Input defaultValue="" placeholder="請輸入學號" type="text" onChange={(e) => setStudentId(e.target.value)} />
        </FormGroup>
        {/* <FormGroup>
          <label>Password</label>
          <Input
            defaultValue=""
            placeholder="Password"
            type="password"
          />
        </FormGroup> */}
        <Button block className="btn-round" color="default"
          onClick={() => getStudentObj()}>
          登入
        </Button>
      </div>
      <div className="modal-footer no-border-footer">
        <span className="text-muted text-center">
          {/* Looking{" "}
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            create an account
          </a>{" "}
          ? */}
          本網站尚未開放註冊
        </span>
      </div>
    </Modal>
  </>);
}

// export default WhiteNavbar;

// export default ColorNavbar;
// const mapState = state => ({
//   // availableCompanies: state.runtime.availableCompanies,
//   user: state.user,
//   // currentCompany: state.comp.companyId || '',
// });
// const mapDispatch = {
//   setCompany,
//   logout,
// };
// export default withCookies(WhiteNavbar);
export default WhiteNavbar;
// export default connect(mapState, mapDispatch)(WhiteNavbar);
